<template>
  <div id="DetailForm">
    <div class="row">
      <ProgressHeader :step="step" />
      <div class="content_area">
        <div class="form_modal">
          <div class="card_show">
            <iframe
              height="812px"
              width="375px"
              marginwidth="0"
              marginheight="0"
              scrolling="No"
              frameborder="0"
              :src="demo_url"
            ></iframe>
          </div>
          <div class="form_box">
            <div class="preview_header">
              <button
                @click="$store.commit('SetPreview', true)"
                class="preview_btn"
              >
                預覽
              </button>
            </div>
            <transition-group name="fade">
              <ThemeAndColor
                v-if="step == 0"
                v-model="form_data"
                ref="ThemeAndColor"
                key="ThemeAndColor"
                v-on:next-step="ChangeStep(1)"
              />
              <PersonalInfo
                v-if="step == 1"
                v-model="form_data"
                ref="PersonalInfo"
                key="PersonalInfo"
                v-on:next-step="ChangeStep(1)"
              />
              <CompanyInfo
                v-if="step == 2"
                v-model="form_data"
                ref="CompanyInfo"
                key="CompanyInfo"
                v-on:next-step="ChangeStep(1)"
              />
              <ImageEditor
                v-if="step == 3"
                v-model="form_data"
                ref="ImageEditor"
                key="ImageEditor"
                v-on:next-step="ChangeStep(1)"
              />
              <PayInfo
                v-if="step == 4"
                v-model="user_data"
                :programs="programs"
                ref="PayInfo"
                key="PayInfo"
                v-on:next-step="ChangeStep(1)"
              />
            </transition-group>
            <div class="form_footer">
              <button v-show="step > 0" @click="ChangeStep(0)">上一步</button>
              <button @click="ValidateForm()">{{ NextText }}</button>
            </div>
          </div>
        </div>
      </div>

      <p style="padding-top: 100px"></p>
    </div>
  </div>
</template>

<script>
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
import ProgressHeader from '@/components/DetailForm/ProgressHeader';
import ThemeAndColor from '@/components/DetailForm/ThemeAndColor';
import ImageEditor from '@/components/DetailForm/ImageEditor';
import PersonalInfo from '@/components/DetailForm/PersonalInfo';
import CompanyInfo from '@/components/DetailForm/CompanyInfo';
import PayInfo from '@/components/DetailForm/PayInfo';
import axios_plugin from '@/common/axios_plugin';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'DetailForm',
  mixins: [SmoothScrollResister, axios_plugin],
  components: {
    ProgressHeader,
    ThemeAndColor,
    ImageEditor,
    PersonalInfo,
    CompanyInfo,
    PayInfo,
  },
  data() {
    return {
      form_data: {
        page: [
          {
            name: '',
            title: '',
            info_1: '',
            info_2: '',
            color: '#000000',
            buttons: [
              {
                type: 'phone',
                title: '行動電話',
                value: '',
              },
              {
                type: 'email',
                title: '電子信箱',
                value: '',
              },
              {
                type: 'address',
                title: 'LINE',
                value: '',
              },
              {
                type: 'website',
                title: '個人網站',
                value: '',
              },
            ],
            files: {
              cover: null,
              logo: null,
            },
          },
          {
            name: '',
            title: '',
            info_1: '',
            info_2: '',
            color: '#000000',
            buttons: [
              {
                type: 'address',
                title: '公司地址',
                value: '',
              },
              {
                type: 'phone',
                title: '公司電話',
                value: '',
              },
              {
                type: 'website',
                title: '官方網站',
                value: '',
              },
              {
                type: 'other',
                title: '公司統編',
                value: '',
              },
            ],
            files: {
              cover: null,
              logo: null,
            },
          },
        ],
        template: 1,
        color: '#000000',
      },
      user_data: {
        name: '',
        phone: '',
        email: '',
        comment: '',
        program: 0,
        agree: false,
        payway: 'reserve',
      },
      demo_url: 'https://www.yongxin-design.com/demo_card/',
      programs: null,
      step: 0,
      meta_data: null,
    };
  },
  methods: {
    ChangeStep(val) {
      if (val == 1) {
        this.step == 4
          ? this.SendFormData()
          : (this.step = this.step >= 5 ? 5 : this.step + 1);
      } else {
        this.step = this.step <= 0 ? 0 : this.step - 1;
      }
    },
    ValidateForm() {
      if (this.step == 0) {
        this.$refs.ThemeAndColor.Validate();
      } else if (this.step == 1) {
        this.$refs.PersonalInfo.Validate();
      } else if (this.step == 2) {
        this.$refs.CompanyInfo.Validate();
      } else if (this.step == 3) {
        this.$refs.ImageEditor.Validate();
      } else if (this.step == 4) {
        this.$refs.PayInfo.Validate();
      }
    },
    async GetPrograms() {
      let result = await this.SendGetData(
        `${process.env.VUE_APP_BASE_API}/Programs/Read.php`
      );
      if (result != 'error') {
        this.programs = result.data;
        // console.log(result.data)
        this.user_data.program = this.$route.query.p
          ? this.$route.query.p
          : this.programs[0].id;
        this.meta_data = GetMetaData('填寫詳細資料', '', '');
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
    async SendFormData() {
      let vm = this;
      let form_data = new FormData();
      let page_data = [];
      let logo = vm.form_data.page[0].files.logo;
      form_data.append('logo[]', logo);

      vm.form_data.page.forEach((page) => {
        form_data.append('cover[]', page.files.cover);
        form_data.append('type', '詳細');
        let tmp_page = Object.assign(page);
        delete tmp_page.files;
        page_data.push(tmp_page);
      });
      form_data.append('card_info', JSON.stringify(page_data));
      form_data.append('contact_info', JSON.stringify(vm.user_data));
      form_data.append('template', vm.form_data.template);
      form_data.append('color', vm.form_data.color);
      form_data.append('payway', vm.user_data.payway);

      let result = await this.PostFormData(
        `${process.env.VUE_APP_BASE_API}/Orders/Create.php`,
        form_data
      );
      if (result != 'error') {
        // console.log(result.data);
        document.getElementById('DetailForm').innerHTML += result.data;
        document.getElementById('newebpay-form').submit();
      }
    },
  },
  watch: {
    form_data() {
      let query = [];
      query.push({
        type: 'page',
        value: this.step,
      });
      query.push({
        type: 'color',
        value: this.form_data.color.replace('#', ''),
      });
      query.push({ type: 'theme', value: this.form_data.template });
      let person_data = this.form_data.page[0];
      let person_buttons = this.form_data.page[0].buttons;
      person_data.name != ''
        ? query.push({ type: 'name', value: person_data.name })
        : '';
      person_data.title != ''
        ? query.push({ type: 'title', value: person_data.title })
        : '';
      person_data.info_1 != ''
        ? query.push({ type: 'info_1', value: person_data.info_1 })
        : '';
      person_data.info_2 != ''
        ? query.push({ type: 'info_2', value: person_data.info_2 })
        : '';
      person_data.files.cover != null && person_data.files.cover != ''
        ? query.push({ type: 'cover', value: person_data.files.cover })
        : '';
      person_data.files.logo != null && person_data.files.logo != ''
        ? query.push({ type: 'logo', value: person_data.files.logo })
        : '';
      person_buttons[0].value != ''
        ? query.push({ type: 'phone', value: person_buttons[0].value })
        : '';
      person_buttons[1].value != ''
        ? query.push({ type: 'email', value: person_buttons[1].value })
        : '';
      person_buttons[2].value != ''
        ? query.push({ type: 'line', value: person_buttons[2].value })
        : '';
      person_buttons[3].value != ''
        ? query.push({ type: 'website', value: person_buttons[3].value })
        : '';

      let company_data = this.form_data.page[1];
      let company_buttons = this.form_data.page[1].buttons;
      company_data.name != ''
        ? query.push({ type: 'company_name', value: company_data.name })
        : '';
      company_data.info_1 != ''
        ? query.push({
            type: 'company_info_1',
            value: company_data.info_1,
          })
        : '';
      company_data.info_2 != ''
        ? query.push({
            type: 'company_info_2',
            value: company_data.info_2,
          })
        : '';
      company_data.files.cover != null && company_data.files.cover != ''
        ? query.push({ type: 'company_cover', value: company_data.files.cover })
        : '';
      company_buttons[0].value != ''
        ? query.push({
            type: 'company_address',
            value: company_buttons[0].value,
          })
        : '';
      company_buttons[1].value != ''
        ? query.push({ type: 'company_phone', value: company_buttons[1].value })
        : '';
      company_buttons[2].value != ''
        ? query.push({
            type: 'company_website',
            value: company_buttons[2].value,
          })
        : '';
      company_buttons[3].value != ''
        ? query.push({ type: 'company_id', value: company_buttons[3].value })
        : '';

      this.demo_url = 'https://www.yongxin-design.com/demo_card/?';
      query.forEach((item, item_index) => {
        item_index == 0 ? '' : (this.demo_url += '&');
        this.demo_url += item.type + '=' + item.value;
      });
      this.$store.commit('SetDemoUrl', this.demo_url);
    },
    step() {
      if (this.step == 2) {
        let query = [];
        query.push({
          type: 'page',
          value: 2,
        });
        query.push({
          type: 'color',
          value: this.form_data.color.replace('#', ''),
        });
        query.push({ type: 'theme', value: this.form_data.template });
        let person_data = this.form_data.page[0];
        let person_buttons = this.form_data.page[0].buttons;
        person_data.name != ''
          ? query.push({ type: 'name', value: person_data.name })
          : '';
        person_data.title != ''
          ? query.push({ type: 'title', value: person_data.title })
          : '';
        person_data.info_1 != ''
          ? query.push({ type: 'info_1', value: person_data.info_1 })
          : '';
        person_data.info_2 != ''
          ? query.push({ type: 'info_2', value: person_data.info_2 })
          : '';
        person_data.files.cover != null && person_data.files.cover != ''
          ? query.push({ type: 'cover', value: person_data.files.cover })
          : '';
        person_data.files.logo != null && person_data.files.logo != ''
          ? query.push({ type: 'logo', value: person_data.files.logo })
          : '';
        person_buttons[0].value != ''
          ? query.push({ type: 'phone', value: person_buttons[0].value })
          : '';
        person_buttons[1].value != ''
          ? query.push({ type: 'email', value: person_buttons[1].value })
          : '';
        person_buttons[2].value != ''
          ? query.push({ type: 'line', value: person_buttons[2].value })
          : '';
        person_buttons[3].value != ''
          ? query.push({ type: 'website', value: person_buttons[3].value })
          : '';

        let company_data = this.form_data.page[1];
        let company_buttons = this.form_data.page[1].buttons;
        company_data.name != ''
          ? query.push({ type: 'company_name', value: company_data.name })
          : '';
        company_data.info_1 != ''
          ? query.push({
              type: 'company_info_1',
              value: company_data.info_1,
            })
          : '';
        company_data.info_2 != ''
          ? query.push({
              type: 'company_info_2',
              value: company_data.info_2,
            })
          : '';
        company_data.files.cover != null && company_data.files.cover != ''
          ? query.push({
              type: 'company_cover',
              value: company_data.files.cover,
            })
          : '';
        company_buttons[0].value != ''
          ? query.push({
              type: 'company_address',
              value: company_buttons[0].value,
            })
          : '';
        company_buttons[1].value != ''
          ? query.push({
              type: 'company_phone',
              value: company_buttons[1].value,
            })
          : '';
        company_buttons[2].value != ''
          ? query.push({
              type: 'company_website',
              value: company_buttons[2].value,
            })
          : '';
        company_buttons[3].value != ''
          ? query.push({ type: 'company_id', value: company_buttons[3].value })
          : '';

        this.demo_url = 'https://www.yongxin-design.com/demo_card/?';
        query.forEach((item, item_index) => {
          item_index == 0 ? '' : (this.demo_url += '&');
          this.demo_url += item.type + '=' + item.value;
        });
        this.$store.commit('SetDemoUrl', this.demo_url);
      }
    },
  },
  computed: {
    NextText() {
      return this.step == 4 ? '送出' : '下一步';
    },
  },
  created() {
    this.GetPrograms();
  },
  mounted() {
    this.RegisterGsap('DetailForm');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
