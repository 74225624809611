<template src="./template.html"></template>

<script>
export default {
  name: "ThemeAndColor",
  props: {
    value: {
      require: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    ChangeColor(color) {
      let tmp_value = Object.assign({}, this.value)
      tmp_value.color = color
      this.$emit("input", tmp_value)
    },
    ChangeTheme(theme) {
      let tmp_value = Object.assign({}, this.value)
      tmp_value.template = theme
      this.$emit("input", tmp_value)
    },
    Validate() {
      this.$emit("next-step")
    }
  }
}
</script>