<template src="./template.html"></template>

<script>
export default {
  name: "CoompanylInfo",
  props: {
    value: {
      require: true
    }
  },
  data() {
    return {
      errors: []
    }
  },
  methods: {
    UpdateInfo(key, val) {
      let tmp_page = Object.assign({}, this.value)
      tmp_page.page[1][key] = val
      this.$emit("input", tmp_page)
    },
    UpdateButtons(key, val) {
      let tmp_page = Object.assign({}, this.value)
      tmp_page.page[1].buttons[key].value = val
      this.$emit("input", tmp_page)
    },
    Validate() {
      this.errors = []
      if (this.value.page[1]["name"] == "") {
        this.errors.push({
          type: "name",
          content: "請物留空"
        })
      }
      if (this.value.page[1]["info_1"] == "") {
        this.errors.push({
          type: "info_1",
          content: "請物留空"
        })
      }
      if (this.value.page[1].buttons[0].value == "") {
        this.errors.push({
          type: "button_1",
          content: "請物留空"
        })
      }
      if (this.value.page[1].buttons[1].value == "") {
        this.errors.push({
          type: "button_2",
          content: "請物留空"
        })
      }
      if (this.value.page[1].buttons[2].value == "") {
        this.errors.push({
          type: "button_3",
          content: "請物留空"
        })
      }
      if (this.value.page[1].buttons[3].value == "") {
        this.errors.push({
          type: "button_4",
          content: "請物留空"
        })
      }

      if (this.errors.length <= 0) {
        this.GA_CompanyDataForm()
        this.$emit("next-step")
      }
    },
    GetError(key) {
      let error = this.errors.filter(item => item.type == key)
      return error.length <= 0 ? "" : error[0].content
    }
  }
}
</script>