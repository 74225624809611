<template src="./template.html"></template>

<script>
export default {
  name: "ProgressHeader",
  props: {
    step: {
      require: true,
    },
  },
  data() {
    return {
      step_title: [
        "選擇主題與顏色",
        "填寫個人資料",
        "填寫公司資料",
        "上傳圖片與LOGO",
        "填寫付款資料",
      ],
    };
  },
};
</script>